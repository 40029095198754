module.exports = {
  name: '1UP Training',
  logo: 'https://img.genflow.com/manny/checkout/logo.png',
  vendorUUID: '7c16e419-dead-459e-8920-7ed31020b8ea',
  metaData: {
      title: '1UP Training',
      description: 'Welcome to 1UP Training.',
      author: 'GENFLOW',
      url: 'https://www.1uptraining.co.uk',
      image: 'https://img.genflow.com/manny/checkout/logo.png',
  },
  theme: {
      colors: {
          primary: '#bfe81b',
          text: '#697386',
          ctaText: '#000000',
      },
  },
  analytics: {
      gtag: '',
      facebookPixel: '',
      tagManager: '',
      tapfiliate: '',
      // segment: '', - Stored in env
  },
  links: {
      terms: 'https://www.1uptraining.co.uk/terms-conditions',
      privacy: 'https://www.1uptraining.co.uk/privacy-policy',
      support: 'https://support.1uptraining.co.uk/hc/en-us',
      genflow: 'https://genflow.com',
      ios: 'https://apps.apple.com/us/app/1-up-training/id6464066924',
      android: 'https://play.google.com/store/apps/details?id=uk.co.oneuptraining.app',
  },
  forms: {
      errors: {
          existingUser: 'This user already exists, please login.',
          invalidPassword: 'The password needs to have 6 or more characters.',
          unknownError: 'The registration could not be processed.',
          userNotFound: 'Incorrect email/password combination',
      },
  },
  pages: {
      register: {
          title: 'Sign Up to',
          ctaText: 'Register',
          ctaLoadingText: 'Registering',
      },
      login: {
          title: 'Login to',
          ctaText: 'Login',
          ctaLoadingText: 'Logging In',

      },
  },
  products: [
      {
          title: 'Full Access',
          product: 'fullaccess',
          label: null,
      }, 
  ],
};
