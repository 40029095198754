import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useSubscriptionAuth } from '../context/subscriptionAuthContext';
import { vendorUUID } from './constants';

const isBrowser = typeof window !== 'undefined';

const getUserName = (email) => `${email.toLowerCase()}#${vendorUUID}`;

export const getUser = () => isBrowser && Auth.currentAuthenticatedUser();

export const getToken = () => isBrowser && Auth.currentSession();

export const isLoggedIn = async () => {
    try {
        await getToken();
        return true;
    } catch (e) {
        return false;
    }
};

export const login = async ({ email, password }) => {
    const result = await Auth.signIn(
        getUserName(email),
        password,
    );
    return result && result.signInUserSession;
};

export const register = async ({
    firstName, lastName, email, password,
}) => {
    await Auth.signUp({
        username: getUserName(email),
        password,
        attributes: {
            email,
            given_name: firstName,
            family_name: lastName,
        },
    });
    const result = await login({ email, password });
    return result;
};

export const logout = async () => {
    if (isBrowser) await Auth.signOut();
};

export const useLogout = () => {
    const { reset } = useSubscriptionAuth();

    return () => {
        reset();
        logout();
    };
};
