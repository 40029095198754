module.exports = {
    name: 'Morsia',
    logo: 'https://img.genflow.com/morsia/checkout/logo.png',
    vendorUUID: '502903d1-74a2-4b85-b6cd-a7d95ca00050',
    metaData: {
        title: 'Morsia',
        description: 'Join the UK\'s #1 Fitness Vlogger on the best strength and fitness platform on the market',
        author: 'GENFLOW',
        url: 'https://morsialtd.com/',
        image: 'https://img.genflow.com/morsia/checkout/logo.png',

    },
    theme: {
        colors: {
            primary: '#0a4bf2',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: 'G-ECHLMCC817',
        facebookPixel: '1121701698569283',
        tagManager: '',
        tapfiliate: '', // TODO
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://morsialtd.com/pages/terms-and-conditions',
        privacy: 'https://morsialtd.com/pages/privacy-policy',
        support: 'https://support.morsialtd.com/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/gb/app/morsia/id1535495618',
        android: 'https://play.google.com/store/apps/details?id=com.mattdoesfitness.morsia',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            // product: '1monthsale',
            // strikethroughLabel: '13% OFF',
            // label: '40% OFF',
            // coupon: 'CsZ9ZHV6',
        }, {
            title: '3 Months',
            product: '3month',
            // product: '3monthsale',
            // strikethroughLabel: '35% OFF',
            // label: '35% OFF',
            // coupon: 'bK4Wipk0',
        }, {
            title: '12 Months',
            // product: '12monthsale',
            product: '12month',
            // strikethroughLabel: '45% OFF',
            // label: '50% OFF',
            // coupon: 'pytT6ZRb',
        },
    ],
    saleProducts: [
        {
            title: '1 Month',
            product: '1monthsale',
            label: null,
        }, {
            title: '3 Months',
            product: '3monthsale',
            label: null,
        }, {
            title: '12 Months',
            product: '12monthsale',
            label: null,
        },
    ],
};
