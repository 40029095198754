import { useEffect, useState } from 'react';
import countryToCurrency from 'country-to-currency';

export default () => {
    const [currency, setCurrency] = useState(null);
    useEffect(() => {
        (async () => {
            const response = await fetch('https://api.country.is');
            const data = await response.json();
            const country = data?.country;
            const newCurrency = countryToCurrency[country];
            setCurrency(newCurrency);
        })();
    }, []);

    return currency;
};
