module.exports = {
    name: 'Dani Munoz Fit',
    logo: 'https://img.genflow.com/dani-munoz-app/checkout/logo.png',
    vendorUUID: 'a56da3ab-8781-41a9-923e-20daef59ea88',
    metaData: {
        title: 'Dani Munoz Fit',
        description: 'Fall in love with the process and your progress!',
        author: 'GENFLOW',
        url: 'https://app.danimunozfit.com/',
        image: 'https://img.genflow.com/dani-munoz-app/checkout/logo.png',
    },
    theme: {
        colors: { // Note these need to be copied into $theme-colors in src/styles/global.scss
            primary: '#E8B9C7',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://app.danimunozfit.com/terms-conditions',
        privacy: 'https://app.danimunozfit.com/privacy-policy',
        support: 'https://app.danimunozfit.com/support',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/danimunozfit/id1520523698',
        android: 'https://play.google.com/store/apps/details?id=com.fitness.dani',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
            toasts: [
                // {
                //     header: 'A note for existing app users:',
                //     body: 'Please create a new account to join the challenge. Your existing app account will not work for this challenge.',
                // },
            ],
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',
        },
    },
    products: [
        // {
        //     title: 'Strong & Consistent Challenge',
        //     product: 'challenge1',
        //     label: null,
        //     address: true, // Collect address on checkout for this product
        //     additional: true, // Allows this product to stack on top of a subscription product
        // },
        {
            title: '1 Month',
            product: '1month',
            // coupon: 'BB9r6Nmv',
            // label: 'SAVE 40%',
        }, {
            title: '12 Months',
            product: '12month',
            // coupon: '0YWzQSwQ',
            // label: 'SAVE 15%',
        },
    ],
    checkoutSettings: {
        additional: {
            address: true, // By default, collcet address for all additional products
        },
    },
    saleProducts: [
    ],
    // currencies: {
    //     available: [
    //         {
    //             code: 'GBP',
    //             icon: '🇬🇧',
    //         },
    //         {
    //             code: 'SEK',
    //             icon: '🇸🇪',
    //         },
    //         {
    //             code: 'EUR',
    //             icon: '🇪🇺',
    //         },
    //         {
    //             code: 'USD',
    //             icon: '🇺🇸',
    //         },
    //     ],
    //     default: 'EUR',
    // },
};
