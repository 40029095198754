module.exports = {
    name: 'Hardbody',
    logo: 'https://img.genflow.com/hardbody/checkout/logo.png',
    vendorUUID: 'c41f82a3-8a85-4cfe-be1c-596b438cc0a3',
    metaData: {
        title: 'Hardbody',
        description: 'Welcome to Hardbody.',
        author: 'GENFLOW',
        url: 'https://hardbodycoaching.store/',
        image: 'https://img.genflow.com/hardbody/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#b71d28',
            text: '#697386',
            ctaText: '#ffffff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://hardbodycoaching.store/terms-conditions',
        privacy: 'https://hardbodycoaching.store/privacy-policy',
        support: 'https://support.hardbody.store/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/hardbody/id1521292907',
        android: 'https://play.google.com/store/apps/details?id=com.brandonhardbody.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            label: null,
        }, {
            title: '3 Months',
            product: '3month',
        }, {
            title: '12 Months',
            product: '12month',
        },
    ],
};
