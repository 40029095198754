/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React, { useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Tap from '@tapfiliate/tapfiliate-js';
import SubscriptionAuthProvider from './src/context/subscriptionAuthContext';
import NavigationHandler from './src/components/NavigationHandler';
import useIsLoggedIn from './src/utils/auth/useIsLoggedIn';
import BRAND_CONFIG from './src/BRAND_CONFIG';

const { analytics } = BRAND_CONFIG;

// const onRouteUpdate = () => {
//   if (typeof window.yotpo !== 'undefined') {
//     window.yotpo.initWidgets();
//   }
// }

const amplifyConfig = {
    Auth: {
        userPoolId: process.env.GATSBY_AMPLIFY_AUTH_USER_USER_POOL_ID,
        region: process.env.GATSBY_AMPLIFY_AUTH_REGION,
        userPoolWebClientId: process.env.GATSBY_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
        identityPoolId: process.env.GATSBY_AMPLIFY_AUTH_IDENTITY_POOL_ID,
        mandatorySignIn: process.env.GATSBY_AMPLIFY_AUTH_MANDATORY_SIGN_IN,
        authenticationFlowType: process.env.GATSBY_AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE,
    },
};
try {
    Amplify.configure(amplifyConfig);
} catch (e) {
    console.log('Error configuring amplify');
    console.log(e);
}

try {
    if (analytics?.tapfiliate) {
        Tap.init(analytics?.tapfiliate);
    }
} catch (error) {
    console.log('Error configuring tapfiliate');
    console.log(error);
}

const wrapRootElement = ({ element }) => (
    <SubscriptionAuthProvider>
        <NavigationHandler>
            {element}
        </NavigationHandler>
    </SubscriptionAuthProvider>
);

// eslint-disable-next-line import/prefer-default-export
export { wrapRootElement };
// export {onRouteUpdate};
