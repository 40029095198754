// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-index-js": () => import("./../../../src/pages/error/index.js" /* webpackChunkName: "component---src-pages-error-index-js" */),
  "component---src-pages-forgot-my-password-index-js": () => import("./../../../src/pages/forgot-my-password/index.js" /* webpackChunkName: "component---src-pages-forgot-my-password-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-manage-subscription-index-js": () => import("./../../../src/pages/manage-subscription/index.js" /* webpackChunkName: "component---src-pages-manage-subscription-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-subscribe-index-js": () => import("./../../../src/pages/subscribe/index.js" /* webpackChunkName: "component---src-pages-subscribe-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */)
}

