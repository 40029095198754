module.exports = {
    name: 'LEAN',
    logo: 'https://img.genflow.com/LEAN/checkout/logo.png',
    vendorUUID: process.env.GATSBY_VENDOR_UUID,
    metaData: {
        title: 'LEAN',
        description: 'Come and join me on one of the fastest growing female fitness platforms on the market!',
        author: 'GENFLOW',
        url: 'https://leanwithlilly.com/',
        image: 'https://img.genflow.com/LEAN/checkout/logo.png',

    },
    theme: {
        colors: {
            primary: '#2db3b6',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '398577981962009',
        tagManager: '',
        tapfiliate: '28497-f86b14',
    },
    links: {
        terms: 'https://leanwithlilly.com/pages/website-terms-and-conditions',
        privacy: 'https://leanwithlilly.com/policies/privacy-policy',
        support: 'https://support.leanwithlilly.com/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/gb/app/lean-with-lilly/id1563460661',
        android: 'https://play.google.com/store/apps/details?id=com.leanwithlilly.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            // product: '1month',
            product: '1monthsale',
            // coupon: 'CUhRWx5m',
            // label: '10% OFF',
            label: 'Most Popular',
        }, {
            title: '3 Months',
            // product: '3month',
            product: '3monthsale',
            // coupon: 'PMeIlPgV',
            // label: '20% OFF',
            showLabel: false,
            // showTrial: false,
            // showDiscount: false,
        }, {
            title: '12 Months',
            // product: '12month',
            product: '12monthsale',
            // coupon: 'v7Rt5RrW',
            // label: '7 Days Free Trial + Gift!',
            label: 'Biggest Saving',
        },
    ],
    saleProducts: [
        {
            title: '1 Month',
            product: '1monthsale',
            label: null,
        }, {
            title: '3 Months',
            product: '3monthsale',
            label: null,
        }, {
            title: '12 Months',
            product: '12monthsale',
            // label: 'free Band',
        },
    ],
    currencies: {
        available: [
            {
                code: 'GBP',
                icon: '🇬🇧',
            },
            // {
            //     code: 'SEK',
            //     icon: '🇸🇪',
            // },
            {
                code: 'EUR',
                icon: '🇪🇺',
            },
            {
                code: 'USD',
                icon: '🇺🇸',
            },
        ],
        default: 'GBP',
    },
};
