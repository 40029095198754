module.exports = {
    name: 'OWNU',
    logo: 'https://img.genflow.com/ownu/checkout/logo2.png',
    vendorUUID: process.env.GATSBY_VENDOR_UUID,
    metaData: {
        title: 'OWNU',
        description: 'You Don’t Need Any Other Fitness App',
        author: 'GENFLOW',
        url: 'https://www.ownufit.com/',
        image: 'https://img.genflow.com/ownu/checkout/logo2.png',

    },
    theme: {
        colors: { // Note these need to be copied into $theme-colors in src/styles/global.scss
            primary: '#a5c0ab',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '3372202469543314',
        tagManager: '',
        tapfiliate: '20216-6c0086',
        refunder: true,
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://ownufit.com/en-gb/pages/terms-and-conditions',
        privacy: 'https://ownufit.com/en-gb/pages/privacy-policy',
        support: 'https://support.ownuapp.com/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/lt/app/ownu/id1535357952',
        android: 'https://play.google.com/store/apps/details?id=com.ownu.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            // product: '1monthExtendedTrial',
            // product: '1month',
            product: '1monthsale',
            // coupon: 'PiInTQ1V', // £1 first month
            // label: '£1 first month',
        }, {
            title: '3 Months',
            product: '3month',
            // product: '3monthsaleextendedtrialnew',
            product: '3monthsale',
            // coupon: '9arT7woM',
            // label: '25% OFF',
            // showLabel: false,
            // showTrial: false,
            // showDiscount: false,
        }, {
            title: '12 Months',
            product: '12month',
            // product: '12monthsaleextendedtrialnew',
            // product: '12monthsale',
            // coupon: 'SfmmUZRH',
            // label: '30% OFF',
        },
    ],
    saleProducts: [
        {
            title: '1 Month',
            product: '1monthsale',
            label: null,
        }, {
            title: '3 Months',
            product: '3monthsale',
            label: null,
        }, {
            title: '12 Months',
            product: '12monthsale',
            label: null,
        },
    ],
    referralProducts: [
        {
            title: '1 Month',
            product: '1monthsale',
            label: 'Referrals offer applied',
            coupon: 'PB2VpjYI',
        }, {
            title: '3 Months',
            product: '3monthsale',
            label: 'Referrals offer applied',
            coupon: 'LhSr9AGB',
        }, {
            title: '12 Months',
            product: '12monthsale',
            label: 'Referrals offer applied',
            coupon: 'myKvqF92',
        },
    ],
    offers: [
        {
            id: '2weeks',
            products: [
                {
                    title: '1 Month',
                    product: '1monthsaleextendedtrialnew',
                }, {
                    title: '3 Months',
                    product: '3monthsaleextendedtrialnew',
                }, {
                    title: '12 Months',
                    product: '12monthsaleextendedtrialnew',
                },
            ]
        },
        {
            id: '50off',
            products: [
                {
                    title: '1 Month',
                    product: '1monthsale',
                    coupon: 'R1H76Hce'
                },
            ]
        },
    ],
    currencies: {
        available: [
            {
                code: 'GBP',
                icon: '🇬🇧',
            },
            {
                code: 'SEK',
                icon: '🇸🇪',
            },
            {
                code: 'EUR',
                icon: '🇪🇺',
            },
            {
                code: 'USD',
                icon: '🇺🇸',
            },
        ],
        default: 'EUR',
    },
};
