// TODO - Fully remove file

const BRAND_CONFIG = require('../BRAND_CONFIG');

// TEMP FIX - Pass values from BRAND_CONFIG
const { vendorUUID } = BRAND_CONFIG;

const MONTHLY = 'monthly';
const QUARTERLY = 'biAnnually';
const YEARLY = 'annually';

// const MONTHLY = "monthly20";
// const QUARTERLY = "biAnnually20";
// const YEARLY = "annually20";

const PRICES = {
    monthly: '9.99',
    // monthlyReducedPrice: "7.99",
    biAnnually: '53.99',
    // biAnnuallyReducedPrice: "39.99",
    yearly: '83.99',
    // yearlyReducedPrice: "59.99",
    monthlyPerDay: '0.33',
    biAnnuallyPerDay: '0.22',
    yearlyPerDay: '0.16',
    // monthlyReduced: "N/A",
    // biAnnuallyReduced: "N/A",
    // yearlyReduced: "N/A",
    // STUDENT
    monthlyStudent: '8.99',
    biAnnuallyStudent: '48.59',
    yearlyStudent: '75.59',
    // monthlyStudent: '7.19',
    // biAnnuallyStudent: '38.69',
    // yearlyStudent: '58.49'
};

const tintColor = '#2f95dc';

const COLOURS = {
    tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    tabBar: '#fefefe',
    errorBackground: 'red',
    errorText: '#fff',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    noticeBackground: tintColor,
    noticeText: '#fff',

    shreddyYellow: '#FFFA8A',
    shreddyPink: '#FCBCD8',
    shreddyBlue: '#B5E8FA',
    shreddyGreen: '#ACEABB',
    shreddyRed: '#ea4052',
};

const PRODUCTS = 'products';
const FACEMASK = 'facemask';
const VEST = 'vest';
const TEE = 'tee';
const ACTIVEWEAR = 'activewear';
const FEATURED = 'featured';
const ACCESSORIES = 'accessories';
const SHORTS = 'shorts';
const CAP = 'cap';

const HOME_VIDEO = 'https://api-files.sproutvideo.com/file/ea9dd7b9191eefcb63/45835beb839670f0/1080.mp4';

module.exports = {
    vendorUUID,
    COLOURS,
    MONTHLY,
    QUARTERLY,
    YEARLY,
    PRODUCTS,
    FACEMASK,
    VEST,
    TEE,
    ACTIVEWEAR,
    ACCESSORIES,
    SHORTS,
    CAP,
    FEATURED,
    PRODUCT_CATALOG: [PRODUCTS],
    PRICES,
    HOME_VIDEO,

    // RECOMMENDED: {
    //     'boxing': BOXING,
    //     'longResistanceBand': LONG_BAND_RECOMMENDED,
    //     'shortResistanceBand': SHORT_BAND_RECOMMENDED,
    //     'sliderPack': HOME_RANGE_RECOMMENDED,
    //     'abWheel': HOME_RANGE_RECOMMENDED,
    //     'dumbbellPack': HOME_RANGE_RECOMMENDED,
    //     'foamRoller': HOME_RANGE_RECOMMENDED,
    //     'exerciseMat': HOME_RANGE_RECOMMENDED,
    // },
    // ENABLED_BANDS_STRENGTH_SPLIT: ['Light', 'Medium', 'Heavy', 'XHeavy', 'XXHeavy']
};
