import React from 'react';
import { Transition as ReactTransition, TransitionGroup } from 'react-transition-group';

const timeout = 350;
const getTransitionStyles = {
    entering: {
        position: 'absolute',
        opacity: 0,
        marginTop: '-50px',
    },
    entered: {
        marginTop: 0,
        transition: `all ${timeout}ms ease-out`,
        opacity: 1,
    },
    exiting: {
        transition: `all ${timeout / 2}ms ease-in-out`,
        opacity: 0,
    },
};

const Transition = ({ children, location, inProp }) => (
    <TransitionGroup>
        <ReactTransition
            key={location?.pathname}
            in={inProp}
            timeout={{
                enter: timeout,
                exit: timeout,
            }}
        >
            {(status) => (
                <div style={{
                    ...getTransitionStyles[status],
                }}
                >
                    {children}
                </div>
            )}
        </ReactTransition>
    </TransitionGroup>
);

export default Transition;
