import axios from 'axios';

const baseUrl = 'https://api.revenuecat.com/v1/subscribers/';
const revenueCatToken = process.env.GATSBY_REVENUECAT_TOKEN;

const getRevenueCatSubscriptions = (userName) => axios.get(baseUrl + encodeURIComponent(userName), {
    timeout: 10000,
    headers: {
        Authorization: `Bearer ${revenueCatToken}`,
        'Content-Type': 'application/json',
    },
    data: {},
});

const transformSubscriberData = (subscriber) => {
    if (!subscriber || typeof subscriber !== 'object' || !subscriber.entitlements) return false;

    if (!subscriber.subscriptions || !Object.keys(subscriber.subscriptions).length) return false;

    // Get active subscription if it exists
    const subscription = Object.values(subscriber.subscriptions).find((sub) => new Date(sub.expires_date) > Date.now());

    // If no active subscription, return false
    if (!subscription) return false;

    const store = (subscription.store) === 'stripe' ? 'web' : subscription.store;

    return {
        is_active: (new Date(subscription.expires_date) > Date.now()),
        expires_date: new Date(subscription.expires_date),
        original_purchase_date: new Date(subscription.original_purchase_date),
        period_type: subscription.period_type,
        purchase_date: new Date(subscription.purchase_date),
        unsubscribe_detected_at: subscription.unsubscribe_detected_at ? new Date(subscription.unsubscribe_detected_at) : null,
        store,
    };
};

export const isUserSubscribed = async (userName) => {
    if (!userName || typeof userName !== 'string') return false;
    let isSubscribed = null;
    try {
        const result = await getRevenueCatSubscriptions(userName);
        console.log('subscriptions', result);
        isSubscribed = transformSubscriberData(result.data.subscriber);
    } catch (e) {
        console.log(e);
        return false;
    }
    // returns subscription details if there is any (even if expired), or false
    return isSubscribed;
};
