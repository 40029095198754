module.exports = {
  name: 'Rise by Evelyn',
  logo: 'https://img.genflow.com/evelyn/checkout/logo.png',
  vendorUUID: '90947471-502e-4a10-8555-39ec04405853',
  metaData: {
      title: 'Rise by Evelyn',
      description: 'Welcome to Rise by Evelyn.',
      author: 'GENFLOW',
      url: 'https://www.risebyevelyn.com',
      image: 'https://img.genflow.com/evelyn/checkout/logo.png',
  },
  theme: {
      colors: {
          primary: '#fcc17f',
          text: '#697386',
          ctaText: '#ffffff',
      },
  },
  analytics: {
      gtag: '',
      facebookPixel: '',
      tagManager: '',
      tapfiliate: '',
      // segment: '', - Stored in env
  },
  links: {
      terms: 'https://www.risebyevelyn.com/terms-conditions',
      privacy: 'https://www.risebyevelyn.com/privacy-policy',
      support: 'https://support.risebyevelyn.com/hc/en-us',
      genflow: 'https://genflow.com',
      ios: 'https://apps.apple.com/us/app/rise-by-evelyn/id6451439649',
      android: 'https://play.google.com/store/apps/details?id=com.risebyevelyn.app',
  },
  forms: {
      errors: {
          existingUser: 'This user already exists, please login.',
          invalidPassword: 'The password needs to have 6 or more characters.',
          unknownError: 'The registration could not be processed.',
          userNotFound: 'Incorrect email/password combination',
      },
  },
  pages: {
      register: {
          title: 'Sign Up to',
          ctaText: 'Register',
          ctaLoadingText: 'Registering',
      },
      login: {
          title: 'Login to',
          ctaText: 'Login',
          ctaLoadingText: 'Logging In',

      },
  },
  products: [
      {
          title: '1 Month',
          product: '1month',
          label: null,
      }, {
          title: '12 Months',
          product: '12month',
      },
  ],
  saleProducts: [
    {
      title: '1 Month',
      product: '1month',
      label: null,
    }, {
      title: '12 Months',
      product: '12month',
      label: 'Special launch offer',
  },
  ]
};
