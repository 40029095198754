import queryString from 'query-string';
import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { toArray } from 'lodash';
import BRAND_CONFIG from '../BRAND_CONFIG';

export default (store) => {
    if (!store) {
        console.warn('No store provided to useSubscriptionProducts');
    }

    const selectedProducts = useMemo(() => {
        // If a direct link is provided, don't load the default products - prevents access while loading redirect
        if (store?.product) return [];

        const { products, saleProducts, referralProducts, offers = [] } = BRAND_CONFIG;
        const location = typeof window !== 'undefined' ? window.location : {};
        const { ref } = queryString.parse(location.search);
        const tapVid = Cookies.get('tap_vid');
        const offerId = store?.offer;
        const offerProducts = offers.find(({ id }) => id === offerId)?.products;

        const isOffer = !!offerId && !!offerProducts?.length;
        const isSale = store?.code && saleProducts?.length;
        const isReferral = (tapVid) && referralProducts?.length;

        if (isReferral) {
            return referralProducts;
        }
        if (isOffer) return offerProducts;
        if (isSale) {
            return saleProducts;
        }
        return products;
    }, [store]);

    const ready = useMemo(() => toArray(store?.products)?.filter(
        (p) => !!p?.sessionId,
    ).length === selectedProducts?.length, [store?.products, selectedProducts]);

    return {
        selectedProducts,
        ready,
    };
};
