import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";
// import '../styles/homepage.scss'
// import "../styles/responsive.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import NavbarMenu from "./navbar/navbar";
// import FooterMenu from "./footer/footer";
import Transition from './Transition'
// import Coming from '../pages/coming-soon'

/* eslint-disable */
const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  // return <Coming/>;
  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      {/* <NavbarMenu /> */}
      <div>
        <Transition location={location}>
          <main>{children}</main>
        </Transition>
        {/* <FooterMenu /> */}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
