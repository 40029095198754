import React, {
    createContext, useContext, useEffect, useState,
} from 'react';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import BRAND_CONFIG from '../BRAND_CONFIG';
import useDefaultCurrency from '../hooks/useDefaultCurrency';
import { getToken } from '../utils/auth';
import useIsLoggedIn from '../utils/auth/useIsLoggedIn';
import currencyCodeToSymbol from '../utils/currencyCodeToSymbol';
import formatPrice from '../utils/formatPrice';
import { isUserSubscribed } from '../utils/revenueCat';
import { getSession } from '../utils/stripe';
import useSubscriptionProducts from '../hooks/useSubscriptionProducts';

const defaultState = {
    loggedIn: false,
    product: null,
    code: null,
    products: {},
    ready: false,
    user: {},
    subscribed: null,
    subscription: null,
    currency: BRAND_CONFIG?.currencies?.default || BRAND_CONFIG?.currencies?.available?.[0] || null,
    sale: false,
    setup: false,
    debug: false,
    offer: null,
};

// Provides information on subscription status and authentication state
const SubscriptionAuthContext = createContext(defaultState);
const { Provider, Consumer: SubscriptionAuthConsumer } = SubscriptionAuthContext;

const SubscriptionAuthProvider = ({ children }) => {
    const [store, setStore] = useState(defaultState);

    if (store?.debug) { console.log('store: ', store); }

    // Updater
    const update = (key, data) => {
        setStore((prevState) => ({
            ...prevState,
            [key]: data,
        }));
    };

    // Reset store
    const reset = () => {
        setStore({
            ...defaultState,
            setup: true,
        });
    };

    // Handle Login State
    const [loggedIn, user] = useIsLoggedIn();

    useEffect(() => {
        update('loggedIn', loggedIn);
        update('user', user);
    }, [loggedIn]);

    useEffect(() => {
        const { hostname } = window.location;
        const { ref, debug, offer } = queryString.parse(window.location.search);
        if (hostname.includes('127') && ref) {
            const date = new Date();
            date.setHours(200, 0, 0, 0);
            Cookies.set('tap_vid', ref, {
                expires: date,
                path: '/',
            });
        }
        if (debug) {
            update('debug', true);
        }
        if (offer) update('offer', offer);
    }, []);

    const { selectedProducts: productList } = useSubscriptionProducts(store);

    // Fetch stripe checkout sessions and data
    useEffect(() => {
        if (!store?.ready && store?.setup) {
            (async () => {
                try {
                    const IdToken = await getToken();
                    const { jwtToken } = IdToken.idToken || {};
                    const newProducts = {};
                    await Promise.all(
                        productList.map(async (product) => {
                            const session = await getSession(
                                product?.product, jwtToken, store?.code, store?.currency, product?.coupon, product?.address, store?.debug,
                            );
                            if (store?.debug) { console.log('Retrieved session: ', session); }
                            newProducts[product?.product] = {
                                ...product,
                                ...session?.data,
                                currency: currencyCodeToSymbol(session?.data?.currency),
                                subtotal: formatPrice(session?.data.subtotal),
                                total: formatPrice(session?.data.total),
                            };
                        }),
                    );
                    update('products', newProducts);
                    update('ready', true);
                } catch (error) {
                    console.error(error);
                }
            })();
        }
    }, [loggedIn, store?.currency, store?.setup, productList]);

    // Update subscription status
    useEffect(() => {
        if (loggedIn != null) {
            if (!loggedIn) {
                update('subscribed', false);
            } else {
                (async () => {
                    const subscription = await isUserSubscribed(user?.username);
                    update('subscription', subscription);
                    update('subscribed', subscription?.is_active);
                })();
            }
        }
    }, [loggedIn]);

    // If currencies are set, get user's default currency
    const defaultCurrency = useDefaultCurrency();
    useEffect(() => {
        if (BRAND_CONFIG?.currencies?.available?.length) {
            const supportedCurrencies = BRAND_CONFIG?.currencies?.available?.map((
                { code },
            ) => code);
            if (supportedCurrencies.includes(defaultCurrency)) {
                update('ready', false);
                update('currency', defaultCurrency);
            }
        }
    }, [defaultCurrency]);

    return (
        <div className="subscription-provider">
            <Provider value={{ ...store, reset, update }}>
                {children}
            </Provider>
        </div>
    );
};

const useSubscriptionAuth = () => useContext(SubscriptionAuthContext);

export { SubscriptionAuthConsumer, SubscriptionAuthContext, useSubscriptionAuth };
export default SubscriptionAuthProvider;
